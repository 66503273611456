$(document).on('click', 'a', function (e) {
  $('[data-bs-toggle=popover]').each(function () {
    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
      $(this).closest('.popover').remove();
    }
  });
});

$(document).on('click', '.js-popover-custom', function(e) {
  e.preventDefault();
  const itemId = $(this).data('item-id');
  const modelName = $(this).data('model-name');
  const controllerName = $(this).data('controller-name');
  const isClearClient = $(this).data('is-clear-client');
  const isItemName =  $(this).data('item-name');
  const isEdit = $(this).data('is-edit');
  const backUrl = $(this).data('back-url');
  let title = $(this).data('js-popover-title');
  const requestMethod = $(this).data('request-method');
  const callback = $(this).data('callback');


  $('.js-popover-custom').not($(this)).popover('hide').popover('dispose');

  title = title || '本当に削除しますか？';
  const content = `<div class="text-center js-popover-content" aria-back-url=${backUrl} aria-request-method=${requestMethod} aria-item-id=${itemId} aria-model-name=${modelName} aria-controller-name=${controllerName} aria-is-edit=${isEdit} aria-is-clear-client=${isClearClient} aria-item-name=${isItemName} aria-callback=${callback}><span class="btn btn-default js-btn-cancel me-2">いいえ</span><span class="btn btn-primary js-btn-ok">はい</span></div>`;

  showPopover(this, title, content)
  if (controllerName == 'expenses') {
    $('.popover-custom-area').addClass('custom-area')
    $('.popover-arrow').addClass('custom-arrow')
  }
});

$(document).on('click', '.remove-field-dynamic', function (e) {
  e.preventDefault();
  window.removeFieldDynamicContainter = $(e.target).closest('.nested-fields');
});

$(document).on('click', '.popover-custom-area .js-btn-ok', function(e) {
  e.preventDefault();

  const $popoverContainer = $(this).parents('.popover');
  const isClearClient = $(this).parents('.js-popover-content').attr('aria-is-clear-client');
  const isEdit = $(this).parents('.js-popover-content').attr('aria-is-edit');
  const id = $(this).parents('.js-popover-content').attr('aria-item-id');
  const modelName = $(this).parents('.js-popover-content').attr('aria-model-name');
  const itemName = $(this).parents('.js-popover-content').attr('aria-item-name');
  const backUrl = $(this).parents('.js-popover-content').attr('aria-back-url');
  const callback = $(this).parents('.js-popover-content').attr('aria-callback');

  var requestMethod = $(this).parents('.js-popover-content').attr('aria-request-method');
  requestMethod = (requestMethod == 'undefined' || requestMethod == '' || requestMethod == null) ? 'DELETE' : requestMethod;
  if (itemName !== "undefined") {
    $(itemName).trigger('click');
  } else {
    if (isClearClient == 'true') {
      let $nestedElm = window.removeFieldDynamicContainter;
      if ($nestedElm == undefined) $nestedElm = $('#js-popover-custom-' + id).closest('.nested-fields');

      $(this).closest('.popover').remove();
      if (isEdit == 'true') {
        $nestedElm.find('.destroy-mode').val(true);
        $nestedElm.hide();
      } else {
        $nestedElm.remove();
      }
    } else {
      $(this).closest('.popover').remove();
      $.ajax({
        url: modelName,
        method: requestMethod,
        data: { 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
      }).done(function () {
        if (backUrl == "reload") {
          window.location.reload();
        } else if (backUrl !== "undefined") {
          window.location.href = backUrl;
        }
        // $('.card .item-row-' + id).hide('slow');
      });
    }
  }
  $popoverContainer.remove();
  if (callback !== "undefined") {
    window[callback]();
  }
});

$(document).on('click', '.js-popover-partner', function(e) {
  e.preventDefault();
  $('.js-popover-partner').not($(this)).popover('hide')

  const title = '本当に削除しますか?';
  const content = `<div class="text-center js-popover-content"><span class="btn btn-default js-btn-cancel me-2">いいえ</span><span class="btn btn-primary js-btn-ok-partner">はい</span></div>`;
  const _this = $(this);
  showPopover(_this, title, content)
});

$(document).on('click', '.popover-custom-area .js-btn-cancel', function(e) {
  e.preventDefault();

  $(this).closest('.popover').remove();
});

const showPopover = (current, title, content) => {
  $(current).popover({
    html: true,
    title: title,
    content: content,
    placement: 'bottom',
    template: '<div class="popover popover-custom-area" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  }).popover('show');
}
