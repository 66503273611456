$(document).ready(function() {
  $(document).on('change', '.estimate-ordered-item-company-id', function() {
    var input_name = $(this).attr('name').split('[').slice(0, 3).join('[');
    var selectedCompanies = $(this).val();
    var orderDetailsTable = $(this).closest('.ordered-item-area').find('.order-details-table');
    var orderDetailsBody = $(this).closest('.ordered-item-area').find('.order-details-body');

    if (selectedCompanies && selectedCompanies.length > 0) {
      orderDetailsTable.removeClass('hidden');

      orderDetailsBody.find('tr').each(function() {
        var companyId = $(this).find('input[name*="[company_id]"]').val();
        var itemIndex = $(this).find('input[name*="[company_id]"]').attr('name').match(/\[(\d+)\]/)[1];
        var nestedIndex = $(this).find('input[name*="[company_id]"]').attr('name').match(/\[(\d+)\]/g)[1].replace(/\D/g, '');
        
        if (selectedCompanies.indexOf(companyId) === -1) {
          if (!$(this).find('input[name*="[_destroy]"]').length) {
            $(this).append('<input type="hidden" name="' + input_name + '[estimate_ordered_item_companies_attributes][' + nestedIndex + '][_destroy]" value="true">');
          }
          $(this).addClass('hidden');
        } else {
          $(this).removeClass('hidden');
        }
      });

      $(this).find("option:selected").each(function () {
        var $this = $(this);
        var companyName = $this.text();
        var companyId = $this.val();
        var currentTimeMillis = new Date().getTime();

        var existingRow = orderDetailsBody.find('input[value="' + companyId + '"]');
        if (existingRow.length === 0 && companyName != '') {
          var row = `
            <tr>
              <td>${companyName}</td>
              <td><input type="text" name="${input_name}[estimate_ordered_item_companies_attributes][${currentTimeMillis}][company_id]" value="${companyId}" class="hidden">
                <input type="text" name="${input_name}[estimate_ordered_item_companies_attributes][${currentTimeMillis}][quantity]" class="convert-half-width form-control text-right amount price-partner"></td>
              <td><input type="text" name="${input_name}[estimate_ordered_item_companies_attributes][${currentTimeMillis}][price]" class="convert-half-width form-control text-right price price-partner"></td>
            </tr>
          `;
          orderDetailsBody.append(row);
        }
        sum_cost_price($(this));
      });
    } else {
      orderDetailsTable.addClass('hidden');
      orderDetailsBody.html('');
      sum_cost_price($(this));
    }
  });

  $('.orederd-upload').change(e => {    
    $(e.target).closest('.upload-documents').find('.upload-item').remove();
    const length = e.target.files.length;
    let size = $(e.target).closest('.upload-list').children('.upload-item').length;
    for (let i = 0; i < length; i++) {
      const name = e.target.files[i].name;
      const temp = "<div class='upload-item'><span class='index'>" + (++size) + ". </span> <span class='file-name'>" + name + "</span>" +
          "<div class='btn-upload-item ordered-btn-delete-file'>" +
          "<img src='/assets/icon/Delete.svg' alt=''/>" +
          "</div>" +
        "</div>";
      $(e.target).closest('.upload-documents').find('.upload-list').append(temp);
    }

    $(document).on('click', '.ordered-btn-delete-file', function(e) {
      $(this).closest('.upload-item').remove();
    });
  });

  $(document).on('keyup', '.price-partner', function() {
    sum_cost_price($(this));
  })

  function sum_cost_price(ele) {
    var total_price = 0;
    var has_value = false;
    var total_quantity = 0;
    ele.closest('.nested-fields').find('tr:not(.hidden)').find('.price').each(function() {
      if($(this).val() != '') {
        has_value = true;
      }
      var value = parseInt($(this).val().replace(/,/g, '')) || 0;
      var amount = parseInt($(this).closest('tr').find('.amount').val().replace(/,/g, '')) || 0;
      total_quantity += amount;
      total_price += value * amount;
    });
    if(has_value) {
      var value_string = Math.round(total_price/total_quantity).toLocaleString();
      ele.closest('.nested-fields').find('.js-cost-price').val(value_string || 0);
    } else {
      ele.closest('.nested-fields').find('.js-cost-price').val(0);
    }
  }

  $(document).on('change', '.checked-ordered', function() {
    if($(this).is(':checked')) {
      $('#old-cost-price').val($(this).closest('.nested-fields').find('.js-cost-price').val());
      $(this).closest('.nested-fields').find('.js-cost-price').attr('readonly', true).addClass('bg-gray');
      sum_cost_price($(this));
    } else {
      $(this).closest('.nested-fields').find('.js-cost-price').attr('readonly', false).val($('#old-cost-price').val()).removeClass('bg-gray');
    }
  })

  $(document).on('click', '.js-cancel-choose-order', function() {
    $('.toggle-check-all-object').prop('checked', false);
    $('.checkbox-estimate-item').prop('checked', false);
  })

  function calcDiscountOrdered() {
    var totalDiscount = _.toNumber($('#ordered_total_discount').val()) || 0;
    $.each($('#form-special-discount').find('.discount-fields'), function (idx, $elm) {
      var specialDiscount = _.toNumber(parseInt($($elm).find('.js-special-discount').val().replace(/,/g, ''), 10)) || 0;
      totalDiscount += specialDiscount;
    });
    let discount_value = '';
    $(".form-estimate-sheet .estimate-item-fields").filter(":visible").each(function (idx, $parent) {
      const discountElement = $($parent).find(".ordered_item_estimate_ordered_items_discount input");
      discount_value = (discountElement.val() != '' && discountElement.val() != undefined) ? discountElement.val().replace(/,/g, '') : 0;
      let discount = _.toNumber(parseInt(discount_value));
      totalDiscount += discount;
    });

    $('#ordered_total_discount').val('￥ ' + totalDiscount.toLocaleString());
  }
  
  $(document).on('keyup', '.ordered_item_ordered_discounts_amount #js-special-discount, .ordered-item-page input', function(){
    calcDiscountOrdered()
    orderedItemCalcTotal()
  })
  
  // $(document).on('change', '.esimate-sheet-page input', function () {
  //   orderedItemCalcTotal(false)
  // })
  
  orderedItemCalcTotal();

  function orderedItemCalcTotal(isNewPage = false) {
    const marginNumber = _.defaultTo(_.toNumber($('.margin-number-temp').val()), _.toNumber($('#margin-number-temp').val()));
    let totalSellPrice = 0;
    let totalCost = 0;
    let totalAllDiscount = 0;
    let totalAllDiscountFreeTax = 0;
    let totalGrossProfit = 0;
    let summaryTotal = 0;
    let freeTaxTotal = 0;
  
    const processItem = ($elm, isSellPrice = true) => {
        const getNumericValue = ($el) => {
            const value = $el.val();
            if (value !== undefined) {
                return _.toNumber(value.replace(/,/g, '')) || 0;
            }
            return 0;
        };
  
        const costPrice = getNumericValue($($elm).find('.js-cost-price'));
        const amount = getNumericValue($($elm).find('.js-amount'));
        const priceDOM = $($elm).find('.js-price');
        let price = !isNewPage ? getNumericValue(priceDOM) : _.toInteger(marginNumber * costPrice);
        
        if (isNewPage) {
            priceDOM.val(price).change();
        }
  
        const discount = getNumericValue($($elm).find('.js-discount'));
        totalAllDiscount += discount;
  
        const sellPrice = getNumericValue($($elm).find('.sell-price'));
        if ($($elm).find('.js-is_freetax').is(':checked')) {
            freeTaxTotal += sellPrice;
            totalAllDiscountFreeTax += discount;
        }
  
        if (isSellPrice) {
            totalSellPrice += sellPrice;
        } else {
            totalCost += sellPrice;
        }
  
        totalGrossProfit += (price - costPrice) * amount;
  
        return { price, sellPrice, discount };
    };
    $('#form-estimate-nested-sell-price .estimate-item-fields:not(:hidden)').each(function (idx, $elm) {
      processItem($elm);
    });

    // Process all cost items
    $('#form-estimate-nested-cost .estimate-item-fields:not(:hidden)').each(function (idx, $elm) {
        processItem($elm, false);
    });
    // Process special discounts
    let totalSpecialDiscount = 0;
    $('#form-special-discount .discount-fields').each(function (idx, $elm) {
        totalSpecialDiscount += _.toNumber($($elm).find('.js-special-discount').val().replace(/,/g, '')) || 0;
    });
    totalAllDiscount += totalSpecialDiscount;

    // Calculate and update totals
    const tax = 0.1;
    summaryTotal = totalCost + totalSellPrice - totalAllDiscount;
    totalGrossProfit = totalGrossProfit - totalAllDiscount;
    const totalWithTax = totalCost + totalSellPrice - freeTaxTotal - totalAllDiscount + totalAllDiscountFreeTax;
    const totalTax = totalWithTax <= 0 ? 0 : Math.round(totalWithTax * tax);

    $('#total-sell-price').html('￥ ' + totalSellPrice.toLocaleString());
    $('#total-cost-price').html('￥ ' + totalCost.toLocaleString());
    $('#total-all-discount').html('￥ ' + totalAllDiscount.toLocaleString());
    $('#total-gross-profit').html('￥ ' + totalGrossProfit.toLocaleString());
    $('#summary-total').html('￥ ' + summaryTotal.toLocaleString());
    $('#tax-included').html('￥ ' + (summaryTotal + totalTax).toLocaleString());

    calcDiscountOrdered();
  }

  $(document).on('click', '#ordered_confirm_calc', function () {
    var marginNumber = _.toNumber($('.margin-number-temp').val()) || 0;
    const id = $('.ordered-item-page')[0].getAttribute('data-esti-sheet-id');
    if (id) {
      $.ajax({
        method: 'PUT',
        url: `/ordered_items/${id}/update_margin_number`,
        data: { margin_number: marginNumber },
      });
    }
    orderedItemCalcTotal(true);
  });

  $('.delivery_date').daterangepicker({
    locale: ja_date_locale,
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
  });

  $('.delivery_date').on('apply.daterangepicker', function (ev, picker) {
    let format = $(this).data('format') || 'YYYY/MM/DD';
    var data = picker.startDate.format(format);
    var ordered_id = $(this).data('ordered-id');

    $.ajax({
      method: 'put',
      url: `/ordered_items/${ordered_id}/change_delivery_date`,
      data: { delivery_date: data },
      success: function(res) {
        if (res.status) {
          $(`#ordered-item-${ordered_id}`).html(`${data}`);
          $(`#rm-ordered-item-${ordered_id}`).removeClass('hidden');
        } else {
          alert(res.message)
        }
      }
    })
  });

  $(document).on('click', '.remove-delivery-ordered', function() {
    var ordered_item_id = $(this).data('ordered-item-id');

    $.ajax({
      method: 'put',
      url: `/ordered_items/${ordered_item_id}/change_delivery_date`,
      data: { delivery_date: null },
      success: function(res) {
        if (res.status) {
          $(`#ordered-item-${ordered_item_id}`).html('');
          $(`#rm-ordered-item-${ordered_item_id}`).addClass('hidden');
        } else {
          alert(res.message)
        }
      }
    })
  })

  window.orderedItemCalcTotal = orderedItemCalcTotal
});